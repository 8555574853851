import { TouchableOpacity, View } from "react-native";
import Modal from "../../../../../../components/modal";
import Text from "../../../../../../components/Text";
import tailwind from "twrnc";
import NotesFileItem from "../../classes/upcoming_section/notes/notes_file_item";
import { useState } from "react";
import { TEACHERS, API } from "../../../../../../api/instance";
import moment from "moment";
import { AntDesign } from "@expo/vector-icons";
import NotesLinkItem from "../../classes/upcoming_section/notes/notes_link_item";
import Alert from "../../../../../../utils/alert";

const NotesModal = ({ open, setOpen, item }) => {
    // console.log("NotesModal : ", item)
    const [loading, setLoading] = useState(false);

    return (
        <Modal visible={open} setVisible={setOpen} maxHeight={500}>
            <View>
                <View style={tailwind`flex flex-row justify-between items-center`}>
                    <Text style={tailwind`font-bold text-[16px]`}>Notes</Text>

                    <AntDesign
                        onPress={() => setOpen(false)}
                        name="close"
                        size={24}
                        color="black"
                    />
                </View>

                {item.calls &&
                    item.calls
                        .filter((x) => x.noteFiles[0] || x.noteLinks[0])
                        .map((call, i) => {
                            const date = moment.unix(call.slot / 1000).format("Do MMMM");
                            return (
                                <View key={i} style={tailwind``}>
                                    <Text style={tailwind`font-bold mb-3`}>{date}</Text>

                                    {call.noteFiles.map((note, i) => (
                                        <NotesFileItem key={i} note={note} setOpen={setOpen} />
                                    ))}

                                    {call.noteLinks.map((note, i) => (
                                        <NotesLinkItem key={i} note={note} setOpen={setOpen} />
                                    ))}
                                </View>
                            );
                        })}

                {item.classes &&
                    item.classes
                        .filter((x) => x.noteFiles[0] || x.noteLinks[0])
                        .map((call, i) => {
                            const date = moment.unix(call.slot / 1000).format("Do MMMM");
                            return (
                                <View key={i} style={tailwind``}>
                                    <Text style={tailwind`font-bold mb-3`}>{date}</Text>

                                    {call.noteFiles.map((note, i) => (
                                        <NotesFileItem key={i} note={note} setOpen={setOpen} />
                                    ))}

                                    {call.noteLinks.map((note, i) => (
                                        <NotesLinkItem key={i} note={note} setOpen={setOpen} />
                                    ))}
                                </View>
                            );
                        })}


            </View>
        </Modal>
    );
};

export default NotesModal;
