import React, { useState } from "react";
import { Image, TouchableOpacity, View, ActivityIndicator } from "react-native";
import Text from "../../../../../../components/Text";
import useClasses from "../../../../../../context/classes";
import tailwind from "twrnc";
import moment from "moment";
import {
	minutesCallBuffer,
	minutesJoinBuffer,
} from "../../../../../../constants/constants";
import { AntDesign, Feather, Ionicons } from "@expo/vector-icons";
import Modal from "../../../../../../components/modal";
import { TEACHERS } from "../../../../../../api/instance";
import JoinClassDialog from "./join_class_dialog";
import CallDialog from "./call_dialog";
import NotesDialog from "./notes/notes_dialog";
import ChatButton from "./chat_button";
import Alert from "../../../../../../utils/alert";
import QuizDialog from "../../../quizzes/quiz_dialog"
import useUser from "../../../../../../context/user";

export const UpcomingSessionCard = () => {
	const { upcoming, isTeacher } = useClasses();

	const [joinModalOpen, setModalOpen] = useState(false);

	const [notesModalOpen, setNotesModalOpen] = useState(false);

	const [callModalOpen, setCallModal] = useState(false);
	const [callLoading, setCallLoading] = useState(true);

	const sessionDateTime = moment.unix(upcoming.slot / 1000);
	const currentDateTime = moment();

	const sessionStartsInDuration = moment.duration(
		moment(sessionDateTime).diff(currentDateTime)
	);
	const skipClassEnabled =
		upcoming.type === "SESSION" &&
		sessionStartsInDuration.asMinutes() > minutesJoinBuffer;

	const joinCallEnabled =
		sessionStartsInDuration.asMinutes() < minutesJoinBuffer;

	const callTeacherEnabled =
		sessionStartsInDuration.asMinutes() < minutesCallBuffer;

	const [quizModalOpen, setQuizModalOpen] = useState(false);
	const [quizzes, setQuizzes] = useState([]);
	const [call, setCall] = useState(null);

	const handleQuiz = async (item, cls) => {
		setQuizzes(item)
		setCall(cls)
		setQuizModalOpen(true)
	};

	const handleJoinClass = () => {
		if (!joinCallEnabled) {
			return Alert.alert(
				`You can join the class ${minutesJoinBuffer} mins prior`
			);
		}

		if (!upcoming.meeting) {
			return Alert.alert("Meeting link not available");
		}

		setModalOpen(true);
	};

	const handleCallTeacher = async () => {
		if (!callTeacherEnabled) {
			return Alert.alert(
				`You can only initiate the call ${minutesCallBuffer} mins prior to the Class`
			);
		}
		setCallLoading(true);
		setCallModal(true);
		try {
			await TEACHERS.post("/clicktocall/initiate", {
				caller: "USER",
				caller_phone: "9680480916",
				session_id: upcoming.id,
				type: upcoming.type,
			});
			setCallLoading(false);
		} catch (error) {
			Alert.alert("An error occured");
			setCallModal(false);
		}
	};
	return (
		<View style={tailwind`w-full`}>
			<View style={tailwind`flex items-center flex-row justify-between w-full`}>
				<Text style={tailwind`font-bold mt-5 mb-5 text-[18px]`}>
					Next Class
				</Text>

				{skipClassEnabled && <SkipClass />}
			</View>

			<JoinClassDialog
				open={joinModalOpen}
				setOpen={setModalOpen}
				link={upcoming?.meeting?.invite_link}
			/>

			<CallDialog
				open={callModalOpen}
				setOpen={setCallModal}
				loading={callLoading}
			/>

			<NotesDialog
				open={notesModalOpen}
				setOpen={setNotesModalOpen}
				session={upcoming}
			/>

			{call && <QuizDialog
				open={quizModalOpen}
				setOpen={setQuizModalOpen}
				quizzes={quizzes}
				call={call}
			/>}

			<View
				style={tailwind`bg-[#51AADD] px-4 py-4 rounded w-full flex flex-row gap-x-2`}
			>
				<View>
					<Image
						source={{ uri: upcoming?.session?.teachers[0]?.profile?.avatar }}
						style={tailwind`w-[130px] h-[130px] rounded`}
					/>
					<Text style={tailwind`mt-1 text-white font-semibold text-[16px]`}>
						{upcoming?.session?.teachers[0]?.name}
					</Text>

					<View style={tailwind`flex flex-row items-center gap-x-3 mt-2`}>
						<TouchableOpacity
							onPress={handleCallTeacher}
							style={tailwind`bg-white rounded-full w-[30px] h-[30px] flex items-center justify-center`}
						>
							<Ionicons name="call" size={18} color="#2096F3" />
						</TouchableOpacity>

						<ChatButton />
					</View>
				</View>

				<View style={tailwind`flex items-start`}>
					<View style={tailwind`flex flex-row items-start gap-x-2`}>
						<Ionicons name="people-outline" size={20} color="#fff" />
						<Text style={tailwind`text-[#fff] w-[150px]`}>
							{upcoming.topic_name ?? "1 to 1 class"}
						</Text>
					</View>
					<View style={tailwind`flex flex-row items-center gap-x-2 my-2`}>
						<Feather name="video" size={20} color="#fff" />
						<Text style={tailwind`capitalize text-[#fff]`}>
							{upcoming.session.source_language} to{" "}
							{upcoming.session.target_language}
						</Text>
					</View>

					<View style={tailwind`flex flex-row items-center gap-x-2`}>
						<AntDesign name="clockcircleo" size={20} color="#fff" />
						<Text style={tailwind`text-white`}>
							{upcoming.duration / 60000} mins
						</Text>
					</View>

					<View style={tailwind`flex flex-row items-center gap-x-2 mt-2`}>
						<Ionicons name="calendar-outline" size={20} color="#fff" />
						<Text style={tailwind`text-white max-w-[130px]`}>
							{sessionStartsInDuration.asMinutes() < 0
								? `Ongoing (${upcoming.session.slot_start_time || upcoming.slot_start_time
								} - ${upcoming.session.slot_end_time || upcoming.slot_end_time
								})`
								: moment.unix(upcoming.slot / 1000).calendar(null, {
									sameDay: "[Today] hh:mm a",
									nextDay: "[Tomorrow] hh:mm a",
									nextWeek: "ddd MMM D, h:mm a",
									lastDay: "ddd MMM D, h:mm a",
									lastWeek: "ddd MMM D, h:mm a",
									sameElse: "ddd MMM D, h:mm a",
								})}
						</Text>
					</View>

					{(upcoming.attached_quizzes && upcoming.attached_quizzes.length > 0) && (
						<TouchableOpacity
							onPress={() => handleQuiz(upcoming.attached_quizzes, upcoming)}
							style={tailwind`flex flex-row items-center gap-x-2 mt-2`}
						>
							<Ionicons name="file-tray-full-outline" size={20} color="#fff" />
							<Text style={tailwind`text-white underline`}>
								Quizzes
							</Text>
						</TouchableOpacity>
					)}

					{(upcoming.noteFiles[0] || upcoming.noteLinks[0]) && (
						<TouchableOpacity
							onPress={() => setNotesModalOpen(true)}
							style={tailwind`flex flex-row items-center gap-x-2 mt-2`}
						>
							<Ionicons name="document-attach-outline" size={20} color="#fff" />
							<Text style={tailwind`text-white underline`}>
								View Class Material
							</Text>
						</TouchableOpacity>
					)}

					<TouchableOpacity
						onPress={handleJoinClass}
						style={tailwind`mt-2 px-4 py-2 rounded ${joinCallEnabled ? "bg-[#FF995A]" : "bg-[#a6a6a6]"
							}`}
					>
						<Text style={tailwind`text-white font-semibold`}>Join Class</Text>
					</TouchableOpacity>
				</View>
			</View>
		</View>
	);
};

export const SkipClass = () => {
	const { user } = useUser();
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [skip_loading1, setSkipLoading1] = useState(false);

	const [classSkiped, setClassSkiped] = useState(false);

	const { getUpcomingSession, upcoming, isTeacher } = useClasses();
	const [check_skip_res, setCheckSkipRes] = useState(null);

	const handleSkipOld = async () => {
		try {
			setLoading(true);
			await TEACHERS.post("/classes/skip", {
				requested_by: isTeacher ? "TEACHER" : "USER",
				session_id: upcoming.id.toString(),
				type: "SESSION",
			});

			setLoading(true);
			setClassSkiped(true);
			getUpcomingSession();
		} catch (error) {
			console.log(JSON.stringify(error));
			setOpen(false);
			setLoading(false);
			Alert.alert("Could not skip class. Please contact Support");
		}
	};

	let handleSkipClassModal = async () => {
		try {
			console.log("handleSkipClassModal : ", upcoming)
			setSkipLoading1(true)
			let type = ""

			if (upcoming.session_id) {
				type = "SESSION"
			} else {
				type = "WEBINAR"
			}

			setCheckSkipRes(null)
			setOpen(true)
			let params = {
				requested_by: user.is_live_teacher ? "TEACHER" : "USER",
				class_id: upcoming.id,
				class_type: type
			}
			await TEACHERS.post("/classes/skiplimit/check", params)
				.then(response => {
					let res = response.data.data
					console.log("res : ", res)
					setSkipLoading1(false)
					setCheckSkipRes(res)
				})
				.catch(error => {
					console.log(error)
					setOpen(false)
					setCheckSkipRes(null)
					setSkipLoading1(false)
				})

		} catch (error) {
			console.log(error);
			Alert.alert("Could not skip class. Please contact Support");
		}
	};

	let handleSkipClass = async (action) => {
		try {
			if (action == "no") {
				setCheckSkipRes(null)
				setOpen(false);
			} else {
				setLoading(true)
				let type = ""

				if (upcoming.session_id) {
					type = "SESSION"
				} else {
					type = "WEBINAR"
				}

				// setSkipModalOpen(true)
				let params = {
					requested_by: user.is_live_teacher ? "TEACHER" : "USER",
					class_id: upcoming.id,
					class_type: type
				}
				await TEACHERS.post("/class/skip", params)
					.then(response => {
						let res = response.data.data
						setLoading(false)
						setOpen(false);

						if (check_skip_res && check_skip_res.used_skip < check_skip_res.skip_limit) {
							Alert.success("Your class has been successfully skipped and scheduled to the next frequency date");
						}

						if (check_skip_res && check_skip_res.used_skip >= check_skip_res.skip_limit) {
							Alert.success("Your session has lapsed as you had already skipped [used skips] out of total [total skips] classes that was allowed");
						}

						setCheckSkipRes(null)
						setLoading(true);
						setClassSkiped(true);
						getUpcomingSession();
					})
					.catch(error => {
						console.log(error)
						setCheckSkipRes(null)
						setLoading(true);
					})
			}
		} catch (error) {
			console.log(error);
			Alert.alert("Could not skip class. Please contact Support");
		}
	};

	return (
		<View>
			<Text
				// onPress={() => setOpen(true)}
				onPress={() => handleSkipClassModal()}
				style={tailwind`text-[#2296F3] font-semibold underline`}
			>
				Skip Class
			</Text>
			<Modal visible={open} setVisible={setOpen}>
				{classSkiped &&
					<>
						<Text style={tailwind`font-semibold text-[16px]`}>
							Class skiped!
						</Text>

						<Text style={tailwind`mt-5`}>
							Your class has been skipped and rescheduled to a later date
						</Text>

						<View style={tailwind`flex flex-row w-full justify-end mt-3`}>
							<TouchableOpacity
								style={tailwind`bg-white ml-5 rounded-lg`}
								onPress={() => {
									setOpen(false);
									return setClassSkiped(false);
								}}
							>
								<Text style={tailwind`text-[#2296F3]`}>Close</Text>
							</TouchableOpacity>
						</View>
					</>
				}

				{!classSkiped && <View>
					{
						!skip_loading1 && <>
							{!user.is_live_teacher &&
								<View>
									{check_skip_res && check_skip_res.used_skip < check_skip_res.skip_limit &&
										< Text style={tailwind`mt-5`}>
											You have a skip limit of {check_skip_res.skip_limit}, you have consumed  {check_skip_res.used_skip}, and your remaining skip limit is {check_skip_res.skip_limit - check_skip_res.used_skip}. Are you sure you want to skip this class?.
										</Text>
									}

									{check_skip_res && check_skip_res.used_skip >= check_skip_res.skip_limit &&
										< Text style={tailwind`mt-5`}>
											Your skip limit has been exhausted. Out of {check_skip_res.skip_limit}, you have consumed {check_skip_res.used_skip}. If you choose to skip this class, it will be lapsed. Do you still want to skip the session ?.
										</Text>
									}
								</View>
							}

							<View style={tailwind`flex flex-row w-full justify-end mt-3`}>
								{loading ? (
									< View style={tailwind`flex flex-row w-full justify-center mt-3`}>
										<Text><ActivityIndicator size="small" color="#51aadd" /></Text>
									</View>
								) : (
									<>
										<TouchableOpacity
											style={tailwind`bg-white rounded-lg`}
											onPress={() => handleSkipClass("yes")}
										>
											<Text style={tailwind`text-[#2296F3]`}>Yes</Text>
										</TouchableOpacity>
										<TouchableOpacity
											style={tailwind`bg-white ml-5 rounded-lg`}
											onPress={() => handleSkipClass("no")}
										>
											<Text style={tailwind`text-[#2296F3]`}>No</Text>
										</TouchableOpacity>
									</>
								)}
							</View>
						</>
					}
					{
						skip_loading1 &&
						< View style={tailwind`flex flex-row w-full justify-center mt-3`}>
							<Text><ActivityIndicator size="small" color="#51aadd" /></Text>
						</View>

					}

				</View>
				}
			</Modal>
		</View>
	);
};
