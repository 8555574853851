import { createContext, useContext, useState } from "react";

const ClassesFilterContext = createContext();

const useClassesFilter = () => useContext(ClassesFilterContext);

export const ClassesFilterProvider = ({ children }) => {
	// const [range, setRange] = useState({
	// 	startDate: new Date(),
	// 	endDate: new Date(),
	// });

	const [range, setRange] = useState({
		startDate: undefined,
		endDate: undefined,
	});

	// const [range, setRange] = useState(null);

	const [status, setStatus] = useState("");

	const [teacherSelected, setTeacherSelected] = useState(null);
	const [studentSelected, setStudentSelected] = useState(null);

	const [slot, setSlot] = useState("");

	const [statuses, setStatuses] = useState([]);

	const [categorySelected, setCategorySelected] = useState(null);

	const [errorMessage, setErrorMessage] = useState("");

	let [st_date_validate, setStDateValidate] = useState(0);
	let [ed_date_validate, setEdDateValidate] = useState(0);


	return (
		<ClassesFilterContext.Provider
			value={{
				range,
				setRange,
				status,
				setStatus,
				teacherSelected,
				setTeacherSelected,
				slot,
				setSlot,
				statuses,
				setStatuses,
				errorMessage,
				setErrorMessage,
				categorySelected,
				setCategorySelected,
				st_date_validate,
				setStDateValidate,
				ed_date_validate,
				setEdDateValidate,
				studentSelected,
				setStudentSelected
			}}
		>
			{children}
		</ClassesFilterContext.Provider>
	);
};

export default useClassesFilter;
