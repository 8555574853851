import React, { useState } from "react";
import { Image, TouchableOpacity, View } from "react-native";
import tailwind from "twrnc";
import Text from "../../../../../../../components/Text";
import { TEACHERS } from "../../../../../../../api/instance";
import Alert from "../../../../../../../utils/alert";
import { useNavigation } from "@react-navigation/native";

const NotesFileItem = ({ note, setOpen }) => {
  const [loading, setLoading] = useState(false);

  const { navigate } = useNavigation();

  const handleClick = async () => {
    try {
      setLoading(true);
      const { data } = await TEACHERS.get(
        `/notes/files/${note?.noteFile?.id}/url`
      );
      setOpen(false);
      setLoading(false);

      // if (isWeb) {
      console.log("data.data.url : ", data.data.url)
      return navigate("NoteView", { url: data.data.url });
      // }
      // Linking.openURL(data.data.url);
    } catch (error) {
      setLoading(false);
      Alert.alert("Could not open note. Try again");
    }

    // navigate("NoteView");
  };

  return (
    <View style={tailwind`flex flex-row justify-between mb-5 items-center`}>
      <View style={tailwind`flex flex-row items-center`}>
        <Image
          style={tailwind`w-[40px] h-[40px]`}
          source={require("../../../../../../../../assets/ic_note_file.png")}
        />
        <Text style={tailwind`w-[180px] text-[12px] ml-3`}>
          {note?.noteFile?.file_name}
        </Text>
      </View>
      <TouchableOpacity disabled={loading} onPress={handleClick}>
        <Text style={tailwind`text-[12px] font-bold text-[#2196F2]`}>View</Text>
      </TouchableOpacity>
    </View>
  );
};

export default NotesFileItem;
