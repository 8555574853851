import React, { useState, useEffect } from "react";
import { Image, View, ScrollView, Pressable, TouchableOpacity } from "react-native";
import tailwind from "twrnc";
import Text from "../../../../../../components/Text";
import { Ionicons } from "@expo/vector-icons";
import moment from "moment";
import { useNavigation } from "@react-navigation/native";
import useUser from "../../../../../../context/user";
import { TEACHERS, API } from "../../../../../../api/instance";
import Notes from "./notes";

const AllNotes = () => {
    const { navigate } = useNavigation();
    const { user } = useUser();
    let [all_notes, setAllNotes] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        getAllNotes()
    }, []);

    async function getAllNotes() {
        let params = {
            "phone": user.phone
        }
        if (user.is_live_teacher)
            params.app_user_id = user._id

        await TEACHERS.post(`/allnotes`, params)
            .then((response) => {
                let res = response.data.data
                setAllNotes(res)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleRedirect = async () => {
        navigate("StudyMaterial")
    };

    return (
        <View style={tailwind`w-full`}>
            {all_notes.length > 0 &&
                <>
                    <View
                        style={tailwind`flex flex-row justify-between w-full items-center`}
                    >
                        <Text style={tailwind`font-bold mt-5 mb-5 text-[18px]`}>
                            All Notes
                        </Text>

                        <Text onPress={() => handleRedirect()}
                            style={tailwind`text-[#2096F3] font-semibold`}
                        >
                            View All
                        </Text>
                    </View>

                    <ScrollView
                        horizontal
                        style={{ maxWidth: "100%" }}
                    >

                        {all_notes.map((item, i) => (
                            <View style={[
                                tailwind`mr-2 bg-slate-100 mb-5`,
                                { height: 200, justifyContent: "space-between" }
                            ]} key={i}>
                                <Notes item={item} key={i} i={i} />
                            </View>
                        ))}
                    </ScrollView>
                </>
            }
        </View >
    );

};

export default AllNotes;
