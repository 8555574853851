import {
    View,
    Text,
    TouchableOpacity,
    StyleSheet,
    Image,
    Pressable,
    ScrollView,
    FlatList,
    ActivityIndicator
} from "react-native";
import React, { useState, useEffect } from "react";
import tailwind from "twrnc";
import { useNavigation } from "@react-navigation/native";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import Layout from "../../layout";
import ClassesHeader from "../../headers/classes.header";
import PageHeader from "../../headers/page.header";
import { TEACHERS, API } from "../../../../api/instance";
import useUser from "../../../../context/user";
import { height, isWeb, width } from "../../../../constants/constants";
let wd = width <= 500 ? width : 499.99
import NotesModal from "../home/partials/study_material/notes_modal";

export default function StudyMaterial() {
    const { user } = useUser();
    const { navigate } = useNavigation();
    const [loading, setLoading] = useState(false);
    const [all_materials, setAllMaterails] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        getAllStudyMaterials()
    }, []);

    async function getAllStudyMaterials() {
        let params = {
            "phone": user.phone
        }
        if (user.is_live_teacher)
            params.app_user_id = user._id

        setLoading(true)
        await TEACHERS.post(`/all/studymaterials`, params)
            .then((response) => {
                let res = response.data.data
                setAllMaterails(res)
                setLoading(false)

            })
            .catch((error) => {
                console.log(error);
                setLoading(false)

            });
    }

    const getImageSource = (item) => {
        if (item.course_type === "study_material" && item.lesson_details) {
            return item.lesson_details.course?.thumbnail_image_url;
        } else if (item.classes?.length > 0) {
            return item.course?.image;
        } else if (item.calls?.length > 0 && item.course_booking?.course) {
            return item.course_booking.course?.image;
        }
        return ''; // Fallback image or empty string
    };

    const getItemTitle = (item) => {
        if (item.course_type === "study_material" && item.lesson_details) {
            return item.lesson_details.course?.name;
        } else if (item.classes?.length > 0) {
            return item.name;
        } else if (item.calls?.length > 0 && item.course_booking?.course) {
            return item.course_booking.course?.title;
        }
        return 'Unknown'; // Fallback title
    };

    const getItemTopics = (item) => {
        if (item.course_type === "study_material" && item.lesson_details) {
            return `Notes on 1 topic`
        } else if (item.classes?.length > 0) {
            return `Notes on ${item.total_calls_note_attached} topics`
        } else if (item.calls?.length > 0 && item.course_booking?.course) {
            return `Notes on ${item.total_calls_note_attached} topics`
        }
        return 'Unknown'; // Fallback title
    };

    const handleClick = async (item) => {
        if ((item.calls && item.calls.length > 0) || (item.classes && item.classes.length > 0)) {
            setModalOpen(true)
        } else {

            let file_path = item.lesson_details.full_pdf_path
            let params = {
                "file_path": file_path
            }

            await TEACHERS.post(`/studymaterial/signedurl`, params)
                .then((response) => {
                    let res = response.data.data
                    if (res.url)
                        return navigate("NoteView", { url: res.url })
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    return (
        <Layout
            HeaderContent={
                <PageHeader backRoute="Home" pageTitle="All Notes" />
            }
        // scrollView={false}
        >
            <View style={tailwind`flex flex-row w-full mt-4`}>
                <View style={styles.container}>
                    {!loading && (
                        <FlatList
                            data={all_materials}
                            numColumns={3}
                            contentContainerStyle={styles.listContainer}
                            columnWrapperStyle={styles.columnWrapper}
                            renderItem={({ item }) => (
                                <View>
                                    <NotesModal
                                        open={modalOpen}
                                        setOpen={setModalOpen}
                                        item={item}
                                    />
                                    <Pressable style={styles.card}>

                                        <Image
                                            source={{ uri: getImageSource(item) }}
                                            style={styles.image}
                                            resizeMode="contain"
                                        />
                                        <Text style={styles.title}>{getItemTitle(item)}</Text>

                                        <Text style={tailwind`mt-1 text-[12px] text-slate-500 w-[200px] text-center text-blue-400`}>
                                            {getItemTopics(item)}
                                        </Text>

                                        <TouchableOpacity
                                            onPress={() => handleClick(item)}

                                            style={tailwind`mt-1 text-[10px] text-center text-blue-600 underline mb-2`}
                                        >
                                            <Text
                                                style={tailwind`text-[11px] text-center text-blue-600 underline`}
                                            >
                                                View All
                                            </Text>
                                        </TouchableOpacity>
                                    </Pressable>
                                </View>
                            )}
                            keyExtractor={(item) => item.id.toString()}
                        />
                    )}

                    {loading &&
                        <View style={[tailwind`flex-1 bg-white-500`]}>
                            <View style={[tailwind`flex-1 justify-center items-center`]}>
                                <ActivityIndicator size="large" color="#60a5fa" />
                            </View>
                        </View>
                    }

                </View>
            </View>
        </Layout>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        // padding: 10,
        backgroundColor: '#f8f8f8',
    },
    listContainer: {
        paddingBottom: 20,
    },
    columnWrapper: {
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },

    card: {
        backgroundColor: '#ffffff',
        margin: 5,
        padding: 5,
        width: (wd - 70) / 3, // Adjust the width to ensure three columns
        height: 180,  // Fixed height for all cards
        justifyContent: 'center',
        alignItems: 'center',
        // borderRadius: 8,
        elevation: 3, // For shadow on Android
        shadowColor: '#000', // For shadow on iOS
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        // shadowRadius: 4,
    },
    image: {
        width: '100%',
        height: 80, // Adjust height as needed for better fit
        minHeight: 80
    },
    title: {
        padding: 5,
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#333',
        minHeight: 50
    },

});
