import { View, Text, Image, StyleSheet, TouchableOpacity, ActivityIndicator } from "react-native";
import { React, useState } from "react";
import tailwind from "twrnc";
import { Ionicons } from "@expo/vector-icons";
import moment from "moment";
import ChatButton from "../chat_button";
import { useNavigation } from "@react-navigation/native";
import NotesDialog from "../../home/classes/upcoming_section/notes/notes_dialog";
import QuizDialog from "../../quizzes/quiz_dialog"
import useUser from "../../../../../context/user";
import UpdateStatusModal from "../common/update_status";
import Modal from "../../../../../components/modal";
import { TEACHERS } from "../../../../../api/instance";
import Alert from "../../../../../utils/alert";

export default function ScheduledItem({ item, i, statusUpdatedSuccess }) {
    let { user } = useUser();
    const { navigate } = useNavigation();
    let class_item = {};
    let teachers = [];
    let users = [];
    let status = {};

    let class_type = (item.session_id) ? "SESSION" : "WEBINAR"
    let cls_start_time = "";
    let cls_end_time = "";
    let time_duration = "";
    let topic_name = "";

    if (class_type == "SESSION") {
        teachers = item.session.teachers
        users = item.session.users
        cls_start_time = moment(item.slot_start_time, "hh:mm A");
        cls_end_time = moment(item.slot_end_time, "hh:mm A");
        time_duration = cls_end_time.diff(cls_start_time, "minutes");
        if (item.status) status = item.status;
        topic_name = item.topic_name ?? `${item?.session?.target_language} Class`;
    }

    if (class_type == "WEBINAR") {
        teachers = item.classTeachers
        users = item.users
        topic_name = `${item.webinar.name} - ${item.topic}`;
    }

    const [notesModalOpen, setNotesModalOpen] = useState(false);
    const [quizModalOpen, setQuizModalOpen] = useState(false);
    const [quizzes, setQuizzes] = useState([]);
    const [call, setCall] = useState(null);
    const [updateSstatusModalOpen, setUpdateStatusModalOpen] = useState(false);
    const [classSkiped, setClassSkiped] = useState(false)
        ;
    const [skipModalOpen, setSkipModalOpen] = useState(false);
    const [skip_loading, setSkipLoading] = useState(false);
    const [skip_loading1, setSkipLoading1] = useState(false);
    const [check_skip_res, setCheckSkipRes] = useState(null);

    const handleQuiz = async (item, cls) => {
        setQuizzes(item)
        setQuizModalOpen(true)
        setCall(cls)
    };

    const handleUpdateStatus = async (item) => {
        setUpdateStatusModalOpen(true)
    };

    let statusUpdated = () => {
        statusUpdatedSuccess()
    };

    let handleSkipClassModal = async (item) => {
        try {
            setSkipLoading1(true)
            let type = ""

            if (item.session_id) {
                type = "SESSION"
            } else {
                type = "WEBINAR"
            }

            setCheckSkipRes(null)
            setSkipModalOpen(true)
            let params = {
                requested_by: user.is_live_teacher ? "TEACHER" : "USER",
                class_id: item.id,
                class_type: type
            }
            await TEACHERS.post("/classes/skiplimit/check", params)
                .then(response => {
                    let res = response.data.data
                    setSkipLoading1(false)
                    setCheckSkipRes(res)
                })
                .catch(error => {
                    console.log(error)
                    setSkipModalOpen(false)
                    setCheckSkipRes(null)
                    setSkipLoading1(false)
                    // setSkipLoading(false);
                })

        } catch (error) {
            console.log(error);
            Alert.alert("Could not skip class. Please contact Support");
        }
    };

    let handleSkipClass = async (item, action) => {
        try {
            if (action == "no") {
                setCheckSkipRes(null)
                setSkipModalOpen(false)
            } else {
                setSkipLoading(true)
                let type = ""

                if (item.session_id) {
                    type = "SESSION"
                } else {
                    type = "WEBINAR"
                }

                setSkipModalOpen(true)
                let params = {
                    requested_by: user.is_live_teacher ? "TEACHER" : "USER",
                    class_id: item.id,
                    class_type: type
                }
                await TEACHERS.post("/class/skip", params)
                    .then(response => {
                        let res = response.data.data
                        setSkipLoading(false)
                        setSkipModalOpen(false)

                        if (check_skip_res && check_skip_res.used_skip < check_skip_res.skip_limit) {
                            Alert.success("Your class has been successfully skipped and scheduled to the next frequency date");
                        }

                        if (check_skip_res && check_skip_res.used_skip >= check_skip_res.skip_limit) {
                            Alert.success("Your session has lapsed as you had already skipped [used skips] out of total [total skips] classes that was allowed");
                        }

                        setCheckSkipRes(null)
                        statusUpdatedSuccess()
                    })
                    .catch(error => {
                        console.log(error)
                        setSkipModalOpen(false)
                        setCheckSkipRes(null)
                        setSkipLoading(false)
                    })
            }
        } catch (error) {
            console.log(error);
            Alert.alert("Could not skip class. Please contact Support");
        }
    };

    return (
        <View style={tailwind`bg-gray-200 mx-auto my-1 w-full`}>
            <View style={tailwind` p-2`}>

                {class_type == "SESSION" &&
                    <View style={tailwind` flex flex-row w-full`} id={`session_call_id_${item.id}`}>
                        <View style={[tailwind`px-0`, styles.card_sec1]}>
                            <View style={tailwind``}>
                                <Image
                                    source={{ uri: teachers[0]?.profile?.avatar }}
                                    style={tailwind`w-[85px] h-[85px] rounded`}
                                />
                                <Text style={tailwind`text-left py-1 text-[12px]`}>
                                    {teachers[0]?.name}
                                </Text>
                            </View>
                        </View>

                        <View style={[tailwind`px-2 `, styles.card_sec2]}>

                            <Text>{topic_name}</Text>
                            <Text
                                style={tailwind``}
                            >
                                1 To 1 Class
                            </Text>
                            <View style={[tailwind`flex-row flex items-center pt-2`]}>
                                <ChatButton class_user={users[0]} teacher={teachers[0]} />

                                <Text style={[tailwind`pl-1 text-[12px]`]}>
                                    {users[0]?.name}
                                </Text>
                            </View>

                            <View style={[tailwind`flex-row pt-1 items-center`]}>
                                <Ionicons name="calendar-outline" size={18} color="#878787" />
                                <Text style={[tailwind`pl-1 text-[12px]`]}>
                                    {moment.unix(item.slot / 1000).format("hh:mm A MMM Do YYYY")}
                                </Text>
                            </View>

                            {(item.attached_quizzes?.length > 0 || item.attached_quizzes?.length > 0) && (
                                <>
                                    {call && <QuizDialog
                                        open={quizModalOpen}
                                        setOpen={setQuizModalOpen}
                                        quizzes={quizzes}
                                        call={call}
                                    />
                                    }

                                    <View style={[tailwind`flex-row pt-1`]}>
                                        <TouchableOpacity
                                            onPress={() => handleQuiz(item.attached_quizzes, item)}
                                            style={tailwind`flex flex-row items-center gap-x-1`}
                                        >
                                            <Ionicons
                                                name="file-tray-full-outline"
                                                size={18}
                                                color="#878787"
                                            />
                                            <Text style={tailwind`text-[12px] mt-[-2px] underline `}>
                                                Quizzes
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                </>
                            )}

                            {(item.noteFiles?.length > 0 || item.noteLinks?.length > 0) && (
                                <>
                                    <NotesDialog
                                        open={notesModalOpen}
                                        setOpen={setNotesModalOpen}
                                        session={item}
                                    />
                                    <View style={[tailwind`flex-row pt-1`]}>
                                        <TouchableOpacity
                                            onPress={() => setNotesModalOpen(true)}
                                            style={tailwind`flex flex-row items-center gap-x-1`}
                                        >
                                            <Ionicons
                                                name="document-text-outline"
                                                size={18}
                                                color="#878787"
                                            />
                                            <Text style={tailwind`text-[12px] mt-[-2px] underline `}>
                                                View Class Material
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                </>
                            )}
                        </View>

                        <View style={[tailwind`px-0 `, styles.card_sec3]}>
                            {status?.name && status?.name == "Schedule" && (
                                <Text
                                    style={tailwind`text-center bg-[#2196f3] text-white text-xs p-0.5 rounded dark:bg-[#2196f3] dark:text[#2196f3]`}
                                >
                                    {item.status.name}
                                </Text>
                            )}

                            <View style={[tailwind`flex-row py-2`]}>
                                <Text
                                    style={tailwind`text-center bg-[#607d8b] text-white text-[12px] py-0.5 px-1 rounded dark:bg-[#607d8b] dark:text[#607d8b]`}
                                >
                                    {item.session?.source_language.substring(0, 2)}
                                </Text>

                                {item.session.call_type == "video" && (
                                    <Ionicons
                                        name="videocam"
                                        size={18}
                                        color="#2196f3"
                                        style={tailwind`px-1`}
                                    />
                                )}

                                {item.session.call_type == "audio" && (
                                    <Ionicons
                                        name="call"
                                        size={18}
                                        color="#2196f3"
                                        style={tailwind`px-1`}
                                    />
                                )}

                                <Text
                                    style={tailwind`text-center bg-[#607d8b] text-white text-[12px] py-0.5 px-1 rounded dark:bg-[#607d8b] dark:text[#607d8b]`}
                                >
                                    {item.session?.target_language.substring(0, 2)}
                                </Text>
                            </View>

                            <View style={[tailwind`flex-row`]}>
                                <Text
                                    style={tailwind`text-center bg-[#607d8b] text-white text-[12px] py-0.5 px-1 rounded dark:bg-[#607d8b] dark:text[#607d8b]`}
                                >
                                    {time_duration} mins
                                </Text>
                            </View>

                            {
                                // user.is_live_teacher && (+item.slot < moment().subtract(70, "m").format('x')) &&

                                user.is_live_teacher &&
                                <>
                                    <UpdateStatusModal
                                        modalOpen={updateSstatusModalOpen}
                                        setModalOpen={setUpdateStatusModalOpen}
                                        call={item}
                                        getCalls={statusUpdated}
                                    />

                                    {
                                        +item.slot > moment().startOf("day").format('x') && +item.slot < moment().endOf("day").format('x') &&
                                        < View style={[tailwind`flex-row pt-1 justify-end`]}>
                                            <TouchableOpacity
                                                onPress={() => handleUpdateStatus(item)}
                                                style={tailwind`text-center bg-[#FF995A] p-1 rounded`}
                                            >
                                                <Text style={tailwind`text-[12px] text-white`}>
                                                    Update Status
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                    }
                                </>
                            }

                            {!user.is_live_teacher && +item.slot > moment().subtract(20, "m").format('x') &&
                                <>
                                    <TouchableOpacity
                                        onPress={() => handleSkipClassModal(item)}
                                        style={tailwind``}
                                    >
                                        <Text
                                            style={tailwind`text-[#2296F3] underline mt-2 text-[12px]`}
                                        >
                                            Skip Class
                                        </Text>
                                    </TouchableOpacity>

                                    <Modal visible={skipModalOpen} setVisible={setSkipModalOpen}>
                                        {
                                            classSkiped &&
                                            <>
                                                <Text style={tailwind`font-semibold text-[16px]`}>
                                                    Class skiped!
                                                </Text>

                                                <Text style={tailwind`mt-5`}>
                                                    Your class has been skipped and rescheduled to a later date
                                                </Text>

                                                <View style={tailwind`flex flex-row w-full justify-end mt-3`}>
                                                    <TouchableOpacity
                                                        style={tailwind`bg-white ml-5 rounded-lg`}
                                                        onPress={() => {
                                                            setSkipModalOpen(false);
                                                            return setClassSkiped(false);
                                                        }}
                                                    >
                                                        <Text style={tailwind`text-[#2296F3]`}>Close</Text>
                                                    </TouchableOpacity>
                                                </View>
                                            </>
                                        }
                                        {!classSkiped && <View>
                                            {
                                                !skip_loading1 && <>
                                                    {!user.is_live_teacher &&
                                                        <View>
                                                            {check_skip_res && check_skip_res.used_skip < check_skip_res.skip_limit &&
                                                                < Text style={tailwind`mt-5`}>
                                                                    You have a skip limit of {check_skip_res.skip_limit}, you have consumed  {check_skip_res.used_skip}, and your remaining skip limit is {check_skip_res.skip_limit - check_skip_res.used_skip}. Are you sure you want to skip this class?.
                                                                </Text>
                                                            }

                                                            {check_skip_res && check_skip_res.used_skip >= check_skip_res.skip_limit &&
                                                                < Text style={tailwind`mt-5`}>
                                                                    Your skip limit has been exhausted. Out of {check_skip_res.skip_limit}, you have consumed {check_skip_res.used_skip}. If you choose to skip this class, it will be lapsed. Do you still want to skip the session ?.
                                                                </Text>
                                                            }
                                                        </View>
                                                    }

                                                    <View style={tailwind`flex flex-row w-full justify-end mt-3`}>
                                                        {skip_loading ? (
                                                            < View style={tailwind`flex flex-row w-full justify-center mt-3`}>
                                                                <Text><ActivityIndicator size="small" color="#51aadd" /></Text>
                                                            </View>
                                                        ) : (
                                                            <>
                                                                <TouchableOpacity
                                                                    style={tailwind`bg-white rounded-lg`}
                                                                    onPress={() => handleSkipClass(item, "yes")}
                                                                >
                                                                    <Text style={tailwind`text-[#2296F3]`}>Yes</Text>
                                                                </TouchableOpacity>
                                                                <TouchableOpacity
                                                                    style={tailwind`bg-white ml-5 rounded-lg`}
                                                                    onPress={() => handleSkipClass(item, "no")}
                                                                >
                                                                    <Text style={tailwind`text-[#2296F3]`}>No</Text>
                                                                </TouchableOpacity>
                                                            </>
                                                        )}
                                                    </View>
                                                </>
                                            }
                                            {
                                                skip_loading1 &&
                                                < View style={tailwind`flex flex-row w-full justify-center mt-3`}>
                                                    <Text><ActivityIndicator size="small" color="#51aadd" /></Text>
                                                </View>

                                            }

                                        </View>
                                        }
                                    </Modal>

                                </>
                            }
                        </View>
                    </View>
                }

                {class_type == "WEBINAR" &&
                    <View style={tailwind` flex flex-row w-full`} id={`webinar_call_id_${item.id}`}>
                        <View style={[tailwind`px-0`, styles.card_sec1]}>
                            <View style={tailwind``}>
                                <Image
                                    source={{ uri: teachers[0]?.profile?.avatar }}
                                    style={tailwind`w-[85px] h-[85px] rounded`}
                                />
                                <Text style={tailwind`text-left py-1 text-[12px]`}>
                                    {teachers[0]?.name}
                                </Text>
                            </View>
                        </View>

                        <View style={[tailwind`px-2 `, styles.card_sec2]}>
                            <Text>{topic_name}</Text>
                            <Text
                                style={tailwind``}
                            >
                                Group Class
                            </Text>
                            <View style={[tailwind`flex-row flex items-center pt-2`]}>
                                <ChatButton class_user={user} teacher={teachers[0]} />
                                <Text style={[tailwind`pl-1 text-[12px]`]}>
                                    {teachers[0]?.name}
                                </Text>
                            </View>

                            <View style={[tailwind`flex-row pt-1 flex items-center`]}>
                                <Ionicons name="calendar-outline" size={18} color="#878787" />
                                <Text style={[tailwind`pl-1 text-[12px]`]}>
                                    {moment.unix(item.slot / 1000).format("hh:mm A MMM Do YYYY")}
                                </Text>
                            </View>

                            {(item.attached_quizzes?.length > 0 || item.attached_quizzes?.length > 0) && (
                                <>
                                    {call && <QuizDialog
                                        open={quizModalOpen}
                                        setOpen={setQuizModalOpen}
                                        quizzes={quizzes}
                                        call={call}
                                    />
                                    }

                                    <View style={[tailwind`flex-row pt-1`]}>
                                        <TouchableOpacity
                                            onPress={() => handleQuiz(item.attached_quizzes, item)}
                                            style={tailwind`flex flex-row items-center gap-x-1`}
                                        >
                                            <Ionicons
                                                name="file-tray-full-outline"
                                                size={18}
                                                color="#878787"
                                            />
                                            <Text style={tailwind`text-[12px] mt-[-2px] underline `}>
                                                Quizzes
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                </>
                            )}

                            {(item.noteFiles?.length > 0 || item.noteLinks?.length > 0) && (
                                <>
                                    <NotesDialog
                                        open={notesModalOpen}
                                        setOpen={setNotesModalOpen}
                                        session={item}
                                    />
                                    <View style={[tailwind`flex-row pt-1`]}>
                                        <TouchableOpacity
                                            onPress={() => setNotesModalOpen(true)}
                                            style={tailwind`flex flex-row items-center gap-x-1`}
                                        >
                                            <Ionicons
                                                name="document-text-outline"
                                                size={18}
                                                color="#878787"
                                            />
                                            <Text style={tailwind`text-[12px] mt-[-2px] underline `}>
                                                View Class Material
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                </>
                            )}
                        </View>

                        <View style={[tailwind`px-0 justify-between`, styles.card_sec3]}>
                            <View>
                                {item.status == "scheduled" && (
                                    <Text
                                        // style={tailwind`text-center bg-[#4caf50] text-white text-xs p-0.5 rounded dark:bg-[#4caf50] dark:text[#4caf50]`}
                                        style={tailwind`text-center bg-[#2196f3] text-white text-xs p-0.5 rounded dark:bg-[#2196f3] dark:text[#2196f3]`}
                                    >
                                        Scheduled
                                    </Text>
                                )}

                                <View style={[tailwind`flex-row pt-2`]}>
                                    <Text
                                        style={tailwind`text-center bg-[#607d8b] text-white text-base py-0.5 px-1 rounded dark:bg-[#607d8b] dark:text[#607d8b] text-xs`}
                                    >
                                        {item.webinar.duration} mins
                                    </Text>
                                </View>
                            </View>

                            {
                                user.is_live_teacher &&
                                // user.is_live_teacher && (+item.slot < moment().subtract(70, "m").format('x')) &&
                                <>
                                    <UpdateStatusModal
                                        modalOpen={updateSstatusModalOpen}
                                        setModalOpen={setUpdateStatusModalOpen}
                                        call={item}
                                        getCalls={statusUpdated}
                                    />

                                    <View style={[tailwind`flex-row pt-1 justify-end`]}>
                                        <TouchableOpacity
                                            onPress={() => handleUpdateStatus(item)}
                                            style={tailwind`text-center bg-[#FF995A] p-1 rounded`}
                                        >
                                            <Text style={tailwind`text-[12px] text-white`}>
                                                Update Status
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                </>
                            }
                        </View>
                    </View>
                }
            </View>
        </View >
    );
}

const styles = StyleSheet.create({
    loadercontainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: "50%",
        marginBottom: "50%",
    },
    activityIndicator: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    card_sec1: {
        width: "25%",
        // backgroundColor: "red"
    },
    card_sec2: {
        width: "55%",
        // backgroundColor: "blue"
    },
    card_sec3: {
        width: "20%",
    },
    activityIndicator: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
});
