import Modal from "../../../../../components/modal";
import tailwind from "twrnc";
import Text from "../../../../../components/Text";
import RangePickerInput from "./range_picker_input";
import { View, TouchableOpacity } from "react-native";
import TimeSlotInput from "./time_slot_input";
import TeacherInput from "./teacher_input";
import StatusInput from "./status_input";
import CategoryInput from "./category_input";
import StudentInput from "./student_input";

import useClassesFilter from "./context";
import { height, isWeb } from "../../../../../constants/constants";
import useUser from "../../../../../context/user";

const FilterModal = ({ modalOpen, setModalOpen, getClasses, scheduled_teachers_list = [], tab = null, scheduled_students_list = [] }) => {
	const { setRange, setStatus, setTeacherSelected, setSlot, errorMessage, setErrorMessage, categorySelected, setCategorySelected, setStudentSelected } =
		useClassesFilter();

	let { user } = useUser();

	let applyFilter = () => {
		getClasses();
		setModalOpen(false);
	};

	let clearAllFilter = () => {
		setRange({ startDate: undefined, endDate: undefined });
		setStatus("");
		setTeacherSelected(null);
		setSlot("");
		setCategorySelected(null)
		setStudentSelected(null)
	};

	return (
		<Modal visible={modalOpen} setVisible={setModalOpen} maxHeight={420}>
			<View>
				<View
					style={tailwind`border-b border-[#9E9E9E] pb-2 flex flex-row items-center justify-between`}
				>
					<Text style={tailwind`font-semibold text-[16px]`}>Filters</Text>
					<Text
						onPress={clearAllFilter}
						style={tailwind`font-semibold text-[#878787] text-[12px]`}
					>
						Clear All
					</Text>
				</View>

				<RangePickerInput />
				<TimeSlotInput />
				{!user.is_live_teacher && (tab && tab == "scheduled") &&
					< TeacherInput
						scheduled_teachers_list={scheduled_teachers_list}
						tab={tab} />
				}
				{user.is_live_teacher &&
					< StudentInput
						scheduled_students_list={scheduled_students_list}
						tab={tab} />
				}
				{/* <StatusInput /> */}
				<CategoryInput />

				{errorMessage != "" && isWeb && <Text style={[tailwind` my-4 w-full h-full text-red-500`]}>{errorMessage}</Text>}

				<View style={[tailwind`w-full flex-row justify-end mt-2`]}>
					<TouchableOpacity
						onPress={() => setModalOpen(false)}
						style={tailwind` my-3`}
					>
						<Text style={tailwind`text-gray-500 text-lg`}>Close</Text>
					</TouchableOpacity>

					<TouchableOpacity onPress={applyFilter} style={tailwind` my-3 mx-3`} disabled={errorMessage != "" && isWeb}>
						<Text style={tailwind`text-blue-500 text-lg`}>Apply</Text>
					</TouchableOpacity>


				</View>
			</View>
		</Modal>
	);
};

export default FilterModal;
