import React, { useState } from "react";
import {
    ActivityIndicator,
    Image,
    TextInput,
    TouchableOpacity,
    View,
} from "react-native";
import tailwind from "twrnc";
import Text from "../../../../../components/Text";
import _ from "lodash";
import { TEACHERS } from "../../../../../api/instance";
import useUser from "../../../../../context/user";
import useClassesFilter from "./context";
import { SelectList } from "react-native-dropdown-select-list";

const StudentInput = ({ scheduled_students_list = [], tab = null }) => {
    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(false);

    const [searchResults, setSearchResults] = useState([]);

    const { studentSelected, setStudentSelected } = useClassesFilter();

    const { user } = useUser();

    const handleSearch = async () => {
        if (searchText === "") return;
        try {
            setLoading(true);
            const { data } = await TEACHERS.get(
                `/users/${user.phone}/teachers?type=WEBINAR&search=${searchText}`
            );
            setSearchResults(data.data);
            setLoading(false);
        } catch (error) {
            console.log(JSON.stringify(error));
            setLoading(false);
        }
    };
    const debouncedSearch = _.debounce(handleSearch, 1000);

    return (
        <View style={tailwind`mt-3`}>
            <View style={tailwind`flex items-center flex-row justify-between`}>
                <Text style={tailwind`text-[#878787] font-semibold text-[16px]`}>
                    Students
                </Text>
                <TouchableOpacity
                    onPress={() => {
                        setSearchText("");
                        setStudentSelected(null);
                        setSearchResults([]);
                    }}
                    style={tailwind`text-[#878787] font-semibold text-[12px]`}
                >
                    <Text>Clear</Text>
                </TouchableOpacity>
            </View>


            {tab == "scheduled" &&
                <View style={tailwind`relative`}>
                    <SelectList
                        setSelected={(id) => {
                            const user = scheduled_students_list.find((item) => item.id === id);
                            setStudentSelected(user) // Set the selected category object
                        }}
                        data={scheduled_students_list.map((item) => ({
                            key: item.id,
                            value: item.name
                        }))}
                        save="key"
                        boxStyles={tailwind`rounded py-2 px-2`}
                        value={setStudentSelected ? setStudentSelected.name : ""}
                    />
                </View>
            }
        </View>
    );
};

export default StudentInput;
